.TopDemanded {
  margin-top: 50px;
}

.MainText {
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: #0d1217;
  font-family: "PlayFairNormal" !important;
}
.NewArrivalsSubText {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #757c81;
  margin-top: 10px;
  margin-bottom: 10px !important;
  font-family: "gilroyNormal" !important;
}
.SubText {
  font-weight: 200;
  font-size: 18px;
  color: #757c81;
  text-align: center;
  line-height: 0.2;
  font-family: "gilroyNormal" !important;
}
.Products {
  display: flex;
  align-items: center;
}
.seeAllTopDemand {
  font-size: 16px;
  font-weight: 600;
  font-family: "gilroyNormal" !important;
  color: #007481;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .MainText {
    font-size: 25px;
  }
  .seeAll {
    margin-top: 57px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .seeAllTopDemand {
    margin-top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .MainText {
    font-size: 26px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .MainText {
    font-size: 26px;
  }
}
@media (min-width: 300px) and (max-width: 575px) {
  .MainText {
    font-size: 22px;
  }
  .TopDemanded {
    margin-top: 40px;
  }
  .Products {
    padding: 0px 10px;
  }
}
