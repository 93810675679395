.line {
  width: 20px;
  height: 2px;
  margin: 4px;
  background: #ffffff;
  border-radius: 5px;
  display: none;
  /* transition: all 0.3s linear; */
}
.NoBorder {
  border: none !important;
}

/* .SearchIcon {
    display: none;
  } */
.searchbar {
  width: 435px;
  height: 46px;
  /* left: 25px; */

  border: 1px solid #1f6cab;
  border-radius: 32px;
  position: relative;
  background: #f4f4f4;
  padding-left: 15px;

  padding-bottom: 3px;
  position: relative;
}
.searchList {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.LogList {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.LogListCont {
  display: none;
  position: absolute;
  background-color: #f4f4f4;
  border-radius: 5px;
  width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  max-height: 700px;
}
.searchListCont {
  display: none;
  position: absolute;
  background-color: #f4f4f4;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  max-height: 700px;
  overflow-y: scroll;
}
/* .searchList:hover .searchListCont{
    display: block;
  } */

.SearchItem {
  color: #505050;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "gilroyNormal" !important;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: pointer;
}
.LogItem {
  color: #505050;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "gilroyNormal" !important;

  padding-bottom: 10px;
  padding-top: 10px;
  cursor: pointer;
}
.NoResult {
  color: #505050;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "gilroyNormal" !important;

  padding-bottom: 20px;
  cursor: pointer;
}
.searchIcon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value to position the icon as desired */
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
  cursor: pointer !important;
  width: 18px;
}
.searchList::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #9c9c9c;
  font-family: "gilroyNormal" !important;
}

.Header_top {
  background-color: #10212f ;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);

  position: relative;
}
.TopIcon {
  color: #007481;
  font-size: 14px;
}
.TopIcon2 {
  color: #007481;
  font-size: 16px;
}
.Header_topFilter {
  background-color: #00464d;
  height: 33px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  text-align: left;
  width: 100%;
  position: relative;
}

.HeaderTop_Text {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}

.Top {
  display: flex;
  align-items: center;
  gap: 15px;
}

.Phone {
  font-family: "gilroyNormal" !important;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 13px;
}

.Mail {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 13px;
  font-family: "gilroyNormal" !important;
}

.Time {
  font-size: 13px;
  color: #fff;
  font-family: "gilroyNormal" !important;
}

.Header_down {
  background-color: #10212f;
  height: 140px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.Header_downFilter {
  background-color: #10212f;
  height: 97px;

  display: flex;
  align-items: flex-start;
}

.Logo {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  height: 80%;
  margin: 13px 0 13px 0;
}

.AddToCart {
  margin-right: 0px;
}
.ItemsNum {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: #ffffff;
  /* background-color: #00464d; */
  top: -10px;
  right: -1px;
  text-align: center;
  font-size: 10px;
  /* padding-left: 4px; */
  padding-top: 4px !important;
  font-weight: 700;
}
.CartItemNum {
  position: relative;
}

a {
  color: #ffffff;
  font-family: "gilroyNormal" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-decoration: none !important;
  /* margin: 0 10px 0 10px; */
}

.Searchbar::placeholder {
  /* padding: 10px; */
  font-size: 14px;
  text-align: left !important;

  font-family: "gilroyNormal" !important;
}

.Logo_Search {
  display: flex;
  justify-content: space-between;
}
.PersonIcon {
  margin-top: 15px;
}
.SearchIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.Icon {
  margin: 0 10px 0 10px;
  cursor: pointer;
}

.Navbar {
  list-style: none;
  display: flex;
  max-width: 100%;

  padding-left: 0;
  font-family: "gilroyNormal" !important;
}

.DrawerLogin {
  display: none;
}

.DrawerLogin p {
  padding-left: 20px;
}

.NavBarMobile {
  position: fixed;
  top: 0vh;
  left: 0;
  padding-left: 0;
  height: 100%;
  width: 67%;
  background: #fcfbf6;
  transform: translateX(0%);
  display: flex;
  flex-direction: column;
  color: #0d1217;
  align-items: flex-start;
  text-align: center;
  transition: all 1s ease;
  overflow-y: scroll;
  font-weight: 600;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
}

.DrawerShortCuts {
  display: none;
}

.DrawerTop {
  display: none;
  height: 80px;
  background: #10212f;
  position: relative;
}

.DrawerTop img {
  margin: 10px;
}

.DrawerClose {
  margin: 10px;
}

.DrawerFooter {
  height: 100%;
  background: #00464d;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: "gilroyNormal" !important;
  display: none;
}
.NotificationOpen {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 28px 208px rgba(0, 0, 0, 0.08),
    0px 8.44118px 62.7059px rgba(0, 0, 0, 0.0521271),
    0px 3.50603px 26.0448px rgba(0, 0, 0, 0.04),
    0px 1.26806px 9.4199px rgba(0, 0, 0, 0.0278729);
  border-radius: 8px;
  position: absolute;
  right: 5%;
  padding: 0 15px 10px 15px;
}
.NoNotification {
  display: none;
}
.Heading {
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 25px 25px;
}
.Notification {
  font-weight: 500;
  color: #0d1217;
  font-family: "PlayFairNormal" !important;
  font-size: 20px;
}
.NotificationTime {
  color: #757c81;
  font-weight: 400;
  font-size: 12px;
  font-family: "gilroyNormal" !important;
}
.MarkReaded {
  font-weight: 400;
  font-size: 13px;
  color: #01b3c5;
  font-family: "gilroyNormal" !important;
}
.NotificationDetails {
  display: flex;
  /* justify-content: space-between; */
  border-bottom: 1px solid #e7e7e7;
  margin: 0 20px;
}
.NotificationText {
  font-weight: 400;
  font-size: 13px;
  color: #0d1217;
  width: 100%;
  font-family: "gilroyNormal" !important;
}
.NotifictionImage {
  width: 45px;
  height: 45px;
}
.LoginOpen {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border: 1px solid #eeeeee;
  backdrop-filter: blur(15px);
  border-radius: 16px;
  width: 507px;
  height: 567px;
  position: absolute;
  top: 5%;
  left: 30%;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.None {
  display: none;
}
.LoginLogo {
  display: flex;
  justify-content: center;
}
.NoLogin {
  display: none;
}
.BackModal {
  background-color: #0d1217;
  opacity: 0.5;
}
.ContactDetails {
  color: #585f67;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 5px;
  font-family: "gilroyNormal" !important;
}
.Input {
  border-bottom: 1px solid #e2e4e5 !important;
  border: none;
  margin-bottom: 20px;
  width: 100%;
}
.Input:focus {
  outline: none;
}
.Flex {
  display: flex;
  margin: 15px;
  justify-content: space-between;
}
.FlexCheck {
  display: flex;
  justify-content: space-between;
}
.TextReg {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin: 15px 0 35px 0;
  font-family: "gilroyNormal" !important;
}
.DiwaliOffersdesk {
  text-align: center;
  color: #fff;
  font-size: 15px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.CheckBox {
  margin-bottom: 15px;
}
.CheckBoxLabel {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  margin-left: 15px;
  font-family: "gilroyNormal" !important;
  cursor: pointer;
}
.LoginButton {
  background: #a49667;
  border-radius: 42px;
  border: 1px;
  width: 100%;
  padding: 15px 25px 15px 25px;
  color: #fafafa;

  font-family: "gilroyNormal" !important;
}

.Validation {
  color: #eb3d25;
  font-family: "gilroyNormal" !important;
  font-size: 14px;
}
.Flexmid {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.Join {
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}
.Background {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
}
.NumFlex {
  display: flex;
}
.regPhoneNumber {
  border-bottom: 1px solid #e2e4e5 !important;
  border: none;
  margin-top: 10px;
  margin-left: 10px;
  width: 100%;
  height: 30px;
  font-size: 14px;
  padding-bottom: 10px;
}
.Catogaries {
  font-size: 12px;
  color: #ffffff !important;
  text-align: start;
  font-family: "gilroyNormal" !important;
  font-weight: 400;
  margin-right: 40px;
  display: block;
  z-index: 1;
}

.regPhoneNumber:focus {
  outline: none;
}
.FlexPhone {
  display: flex;
}
.InputPhone {
  margin-bottom: 20px;
}

.CountryCode {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #333;
}

@media (max-width: 1200px) {
  .Icon {
    width: 20px !important;
  }
  .PersonIcon {
    width: 20px !important;
  }

  .Catogaries {
    margin: 10px 5px 5px 10px;
    padding: 20px;
    border-bottom: 1px solid #f0ebd1;
    width: 100%;
    font-size: 16px;
    color: #0d1217 !important;
    text-align: start;
    font-family: "gilroyNormal" !important;
  }

  .DrawerLogin {
    background: #eeeeee;
    width: 100%;
    display: flex;
    font-weight: 900;
    padding: 25px;
    color: #0d1217;
    font-family: "gilroyNormal" !important;
  }

  .DrawerShortCuts {
    width: 100%;
    display: flex;
    font-weight: 900;
    padding: 25px;
    color: #0d1217;
    font-family: "gilroyNormal" !important;
    border-bottom: 1px solid #0d121721;
    margin-top: -5px;
  }

  .DrawerTop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }

  .DrawerWishList {
    display: block;
  }

  .line {
    display: block;
  }

  .Searchbar {
    width: 180px;
    height: 30px;
    background-position: 10px;
    background-size: 13px;
  }

  ::-webkit-input-placeholder {
    margin-top: -2px;
    font-size: 10px;
    padding-left: 30px;
  }

  a {
    font-size: 5px;
  }

  .Logo {
    height: 35px;
  }

  .Account {
    display: none !important;
  }

  .Header_down {
    height: 70px;
  }

  .Navbar {
    display: none !important;
  }
  .searchbar {
    display: none;
  }

  .SearchIcons {
    display: flex;
    align-items: center;
  }
  .DrawerFooter {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .Navbar {
    display: none !important;
  }

  .Searchbar {
    display: none !important;
  }

  /* .SearchIcon {
      display: inline-block;
      align-items: center;
    } */

  .Icon {
    width: 20px !important;
  }

  .NotificationOpen {
    width: 80%;
  }
  .Account {
    display: none !important;
  }

  .Time {
    font-size: 8px;
  }

  .Heading {
    flex-direction: column;
  }
  .MarkReaded {
    font-size: 11px;
  }
  .line {
    width: 15px;
  }
}

@media screen and (max-width: 500px) {
  .Top {
    display: none;
  }

  .Time {
    font-size: 6px;
  }
  .CheckBoxLabel {
    font-size: 11px;
  }
  .Join {
    font-size: 11px;
  }

  .HeaderTop_Text {
    padding: 5px;
    display: flex;
    justify-content: right;
    display: none;
  }

  .Logo {
    height: 30px;
  }

  .SearchIcons {
    display: flex;
    align-items: center;
  }

  .line {
    display: block;
  }

  .Account {
    display: none !important;
  }

  .SearchIcons {
    display: flex;
    align-items: center;
  }

  .Header_down {
    align-items: center;
  }
  .NotificationDetails {
    flex-direction: column;
  }
  .NotifictionImage {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .Notification {
    font-size: 16px;
  }
}

.Password::placeholder {
  font-family: "GilroyRegular";
  font-size: 16px;
  line-height: 28px;
  color: #9d9d9d;
}

@media (min-width: 300px) and (max-width: 992px) {
  .Header_top {
    /* display: none; */
    border: none;
    box-shadow: none;
    border: none;
    background: #10212f;
  }
}
