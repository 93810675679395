.Background {
  background: #eeeeee;
}
.Features {
  margin-bottom: 0px !important;
}
.cartEmpty {
  display: flex;
  justify-content: center;
}
.cartListHead {
  font-weight: 600;
  font-size: 30px;
  color: #041a25;
  padding-top: 30px;
  text-align: center;
  font-family: "gilroyNormal";
}
.cartEmpty img {
  width: 200px;
  height: 200px;
}
.cartEmpty {
  display: flex;
  justify-content: center;
}
.cartPara {
  font-weight: 400;
  font-size: 18px;
  color: #041a25;
  padding-top: 10px;
  text-align: center;
  margin: 0px 60px;
  font-family: "gilroyNormal";
}
.btn_shopnow{
  width:175.68px;
  height: 48px;
  border-radius: 4px;
  background-color: #00464D !important;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: none;
  outline: none;
  color: white;
}

@media screen and (max-width: 565px) {
  .cartListHead {
    font-size: 25px;
  }
  .cartPara {
    font-size: 15px;
  }
}

@media (min-width: 300px) and (max-width: 567px) {
  .pageout {
    padding-top: 20px;
    padding-right: 0px !important;
  }
}
